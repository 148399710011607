import { theme } from "antd";
import $color1 from "../colors.scss";
import $color2 from "../colors.scss";
import $color3 from "../colors.scss";
import $color4 from "../colors.scss";
import $color5 from "../colors.scss";
import $color6 from "../colors.scss";
import $color7 from "../colors.scss";
import $color8 from "../colors.scss";

export default {
  algorithm: theme.defaultAlgorithm,
  token: {
    // // Primary brand color
    // colorPrimary: $color1,

    // // Info, success, warning, error colors
    // colorInfo: "#1677ff", // Default AntD blue
    // colorSuccess: "#52c41a", // Green
    // colorWarning: "#faad14", // Yellow
    // colorError: $color5, // Red

    // // Neutral colors
    // colorText: $color3, // Main text
    // colorTextSecondary: $color2, // Secondary text
    // colorTextDisabled: $color7, // Disabled text
    // colorBorder: $color5, // Border color
    // colorBorderSecondary: "#ff4d4f", // Lighter border

    // // Background colors
    // colorBgLayout: "#ff4d4f", // Layout background
    // colorBgContainer: $color5, // Container background
    // colorBgElevated: $color4, // Elevated backgrounds
    // colorBgSpotlight: "rgba(85, 46, 176, 0.85)", // Spotlight overlay

    // // Interactive colors
    // colorLink: $color1,
    // colorLinkHover: $color5,
    // colorLinkActive: $color5,

    // // Shadows
    // colorShadow: "rgba(0, 176, 44, 0.15)",

    colorPrimaryHover: $color6, // Change hover color from #5197fa to orange
    colorLinkHover: $color6,

    // Font family
    fontFamily: "Cormorant Garamond",
  },
};
