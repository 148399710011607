import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col } from "antd";
import "../css/NavigationBar.scss"; // Import the scss file

function NavigationBar() {
  const [activeLink, setActiveLink] = useState("/");
  const navbarRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    if (navbarRef.current) {
      const navbarHeight = navbarRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--navbar-height",
        `${navbarHeight}px`
      );
    }
  }, []);

  return (
    <div className="navigation-bar">
      <div className="navigation-bar-buttons">
        <Row gutter={0} ref={navbarRef}>
          <Col
            span={4}
            className={`colStyle ${activeLink === "/" ? "active" : ""}`}
            onClick={() => setActiveLink("/")}
          >
            <Link className="navLink" to="/">
              Home
            </Link>
          </Col>
          <Col
            span={4}
            className={`colStyle ${activeLink === "/drinks" ? "active" : ""}`}
            onClick={() => setActiveLink("/drinks")}
          >
            <Link className="navLink" to="/drinks">
              Drinks
            </Link>
          </Col>
          <Col
            span={4}
            className={`colStyle ${
              activeLink === "/ingredients" ? "active" : ""
            }`}
            onClick={() => setActiveLink("/ingredients")}
          >
            <Link className="navLink" to="/ingredients">
              Ingredients
            </Link>
          </Col>
          <Col
            span={4}
            className={`colStyle ${activeLink === "/glasses" ? "active" : ""}`}
            onClick={() => setActiveLink("/glasses")}
          >
            <Link className="navLink" to="/glasses">
              Glasses
            </Link>
          </Col>
          <Col
            span={4}
            className={`colStyle ${activeLink === "/units" ? "active" : ""}`}
            onClick={() => setActiveLink("/units")}
          >
            <Link className="navLink" to="/units">
              Units
            </Link>
          </Col>
          <Col
            span={4}
            className={`colStyle ${activeLink === "/about" ? "active" : ""}`}
            onClick={() => setActiveLink("/about")}
          >
            <Link className="navLink" to="/about">
              About
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default NavigationBar;
