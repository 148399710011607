import React, { useRef, useEffect } from "react";
import "../css/ApplicationHeader.scss";

function ApplicationHeader() {
  const headerRef = useRef(null);

  useEffect(() => {
    if (headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerHeight}px`
      );
    }
  }, []);

  return (
    <h1 className="application-header" ref={headerRef}>
      DRY. NEAT. DIRTY.
    </h1>
  );
}

export default ApplicationHeader;
